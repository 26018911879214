import React from "react";
import logoPic from "./assets/logo_pic.png";
import logoText from "./assets/logo_text.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logoText} style={{ height: "15vmin" }} alt="logo" />
        <div style={{ height: 20 }} />
        <img src={logoPic} className="App-logo" alt="logo" />
        <div style={{ height: 20 }} />
        <p
          style={{
            color: "#71A9D1",
            fontSize: "30px",
            fontWeight: "600",
            fontFamily: "Poiret One",
          }}
        >
          Coming soon . . .
        </p>
      </header>
    </div>
  );
}

export default App;
